import React, {useState} from 'react';
import Layout from '../components/layout';
import styled from "styled-components";

const ThankYou = styled.div`
  margin: 0 auto;
  width:50%;
  text-align:center;
  padding:150px;
`
const FormWrapper = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr;
  margin-top:150px;
  background:#adadad;
  width:1280px;
  margin: 150px auto;
  @media(max-width:1350px){
    grid-template-columns:1fr;
    width:960px;
  }
  @media(max-width:1000px){
    grid-template-columns:1fr;
    width:85%;
  }
  h2{
    color:#ffffff;
    align-self:center;
    font-size:40px;
    text-transform:uppercase;
    letter-spacing:3px;
  }
`

const Header = styled.h2`
  text-align:center;
`

const HomeForm = styled.form`
  background:lightgrey;
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  justify-items:center;
  padding:75px;
  @media(max-width:750px){
    padding:25px;
  }
  #contact{
    grid-column-start: 1;
    grid-column-end:   3;
  }
  @media(max-width:750px){
    display:block;
  }
  h3{
    span{
      color:red;
    }
  }
  label{
    width:100%;
  }
  .checky{
    width:100%;
  }
  input[type="text"], input[type="email"] {
    padding: 12px 12px;
    font-size: 20px;
    border:none;
    box-sizing:border-box;
    width:100%;
    border-radius: 20px;
    &:focus{
      outline:0;
    }
  }
  .text-area{
    grid-column-start: 1;
    grid-column-end:   3;
    width:100%;
    textarea {
      height: 150px;
      border:none;
      box-sizing:border-box;
      width:100%;
      border-radius: 20px;
      border:none;
      padding: 12px 12px;
      font-size: 20px;
      border:none;
      box-sizing:border-box;
      &:focus{
        outline:0;
      }
    }
  }
  .submitHand{
      grid-column-start: 1;
      grid-column-end:   3;
     button{
      width:100%;
      margin-top:25px;
      border:none;
      background:green;
      color:#ffffff;
      padding:20px;
      font-size:32px;
      border-radius: 20px;
    }
    &.fail{
      a, span{
        display:block;
        text-align:center;
      }
      button{
        background:grey;
      }
    }
  }  
`

const Contact = () => {

//********FORM FUNCTION ***********/


const [formState, setFormState] = React.useState({
    name: "",
    phoneNumber: "",
    email: "",
    subject: "",
    message: ""
  })
  
  console.log("pushAgain");
  
  const formStateCheck = formState;
  
  const checkValidationName = () => {
    if(formStateCheck.name === ""){
      setShowMessageName(true)
    }
    else{
      setShowMessageName(false)
    }
  }
  
  const checkValidationEmail = () => {
    if(formStateCheck.email === ""){
      setShowMessageEmail(true)
    }
    else{
      setShowMessageEmail(false)
    }
  }
  
  const checkValidationSubject = () => {
    if(formStateCheck.subject === ""){
      setShowMessageSubject(true)
    }
    else{
      setShowMessageSubject(false)
    }
  }
  
  const checkValidationMessage = () => {
    if(formStateCheck.message === ""){
      setShowMessageMessage(true)
    }
    else{
      setShowMessageMessage(false)
    }
  }
  
  const checkValidationSubmit = () => {
  
    const formStateCheck = formState;
  
    if(formStateCheck.name !=="" && formStateCheck.email !=="" && formStateCheck.subject !=="" && formStateCheck.message !==""){
      setFormInvalid(true)
    }
  
  }
  
  
  const onChange = (e) => {
    // if (e.target.type === 'checkbox') {
        
    //   const changedReason = e.target.getAttribute('name');
    //   setFormState({...formState, reasons:{...formState.reasons, [changedReason]: !formState.reasons[changedReason]}});
    //   console.log(formState);
    // } else {
        setFormState({...formState, [e.target.name]: e.target.value });
       
    // }
  
     checkValidationSubmit();
  
  }
  
  
//   const prepareDataForApi = (formData) => {
//   const newFormData = Object.assign({}, formData); // optional if passed cloned copy of formData object or you can also use lodash cloneDeep
//   newFormData.data = {
//     reasonArray:[]
//   };
  
//   Object.keys(newFormData.reasons).forEach(key => {
//     if(newFormData.reasons[key]){
//       newFormData.data.reasonArray.push({reason: key})
//     }
//   });
  
//   delete newFormData.reasons;
  
//   return newFormData;
//   }
  
  //Form Errors and Hiding and Showing
  
  
  const [form, setForm] = useState(true);
  
  function showForm(e) {
    e.preventDefault();
    setForm(true);
  }
  
  const [formFail, setFormFail] = useState(true);
  
  function formFailReset(e){
  e.preventDefault();
  setFormFail(true);
  }
  
  const [formInvalid, setFormInvalid] = useState(false);
  
  
  
  // const [nameVal, setNameVal] = useState(false);
  
  
  const [showMessageName, setShowMessageName] = useState(false)
  
  const [showMessageEmail, setShowMessageEmail] = useState(false)
  
  const [showMessageSubject, setShowMessageSubject] = useState(false)
  
  const [showMessageMessage, setShowMessageMessage] = useState(false)
  
  //End
  
  // Try Catch
  
  
  
  //End
  
  
  const submitForm = async (e) => {
  e.preventDefault();
  
  
  //All validation  
  if (formState.name === "" || formState.email === "" || formState.subject === "" || formState.message === "") {
    setFormInvalid(false)
    
  }
  
  else{
    setFormInvalid(true)
  
    // const newFormData = prepareDataForApi(formState); 
  
    try{
      const response = await fetch("/.netlify/functions/sendmail", {
        method: "POST",
        body: JSON.stringify(formState),
      }) 
  
      if (!response.ok) {
        console.log(response);
  
  
        setFormFail(false);
  
        return
      }
  
      console.log("success email");
      setForm(false);
      
    } catch(e){
    
      console.log("error");
  
    }
  }
  
  }
  
  //**********FORM FUNCTION ENDS*****/

return (
  <Layout>
          {/* FORM STARTS HERE */}

          <div id="contact"></div>
      <FormWrapper>
      <Header>
        Contact Me
      </Header>

      {form ? 
      
      < >
      <HomeForm onSubmit={submitForm} autoComplete="off">
        
      {/* <input type="text" name="package" value={data.datoCmsPricing.title} /> */}
      <label>
        <h3>Name{ showMessageName && <span> *Required</span>}</h3>     
        <input
          type="text"
          name="name"
          value={formState.name}
          onChange={onChange}
          onKeyUp={checkValidationName}
          autoComplete="off"
        />
      </label>
      <label>
        <h3>Email{ showMessageEmail && <span> *Required</span>}</h3>
        <input
          type="email"
          name="email"
          value={formState.email}
          onChange={onChange}
          onKeyUp={checkValidationEmail}
          autoComplete="off"
        />
      </label>
      <label>
        <h3>Subject{ showMessageSubject && <span> *Required</span>}</h3>
        <input
          type="text"
          name="subject"
          value={formState.subject}
          onChange={onChange}
          onKeyUp={checkValidationSubject}
        />
      </label>
      
      
      <div className="text-area">
        <label>
          <h3>Message{ showMessageMessage && <span> *Required</span>}</h3>
          <textarea
            name="message"
            value={formState.message}
            onChange={onChange}
            onKeyUp={checkValidationMessage}
          />
        </label>
      </div>

      {formFail ? 
        <label className= {formInvalid ? `submitHand` : `submitHand fail`}  >

          {formInvalid ? 
            <button type="submit">Submit</button>
          :
            <button disabled type="submit">Submit</button>
          }
          
        </label>
        
      :
      <label className="submitHand fail">
        <span>Something Went Wrong</span>
        <a onClick={formFailReset} href="#">Click to reset form</a>
        <button disabled type="submit">Submit</button>
      </label>
      
      }
      
      
    </HomeForm>

    </>
      
      
      
      : 

      <ThankYou>

          <div>
            <p>Thank you your message has been sent</p>
            
          </div>

          
          <a onClick={showForm} href="#">Send Again</a>

      </ThankYou>
      
    
      
      }

      </FormWrapper>

      {/* fORM ENDS HERE */}
  </Layout>
 )
}

export default Contact;